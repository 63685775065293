//
// Description: plays videoloop videos
// Author: Kim Christiansen
//

/*global jQuery */
(function ($, window) {
  'use strict';

  const $document = $(document),
    $scope = $('.js-videoloop'),
    setup = function () {

      $scope.each(function(){
        this.play();
      });

      $document.on('mouseenter focus', '.js-videoloop', function (e) {
        var $this = $(this);
        if ($this.length) {
          // $this.find('video').get(0).play();
          this.play();
        }
      });

      $scope.on('scrollcontrol/isInView', function (e) {
        this.play();
      });

      $scope.on('scrollcontrol/isNotInView', function (e) {
        this.pause();
      });

    },
    init = function () {

      if ($scope.length) {
        setup();
      }

    };

  init();
}(jQuery, window));
