/*global jQuery */
(function ($, window, document, undefined) {
  'use strict';
  const $document = $(document),
    $window = $(window),

    openDropdown = function ($panel) {

      $panel.attr('aria-hidden', 'false');
      $panel.addClass('is-open');
      $panel.triggerHandler('dropdown/open');

      const panelID = $panel.attr('id');
      const panelGroup = $panel.data('dropdown-group');
      const autoFocus = $panel.data('dropdown-autofocus');
      const $trigger = $('.js-dropdown-trigger[aria-controls="' + panelID + '"]');

      if (autoFocus) {
        $panel.find('input, textarea, a, button, select').first().focus();
      }

      $trigger.each(function () {
        const $this = $(this);
        $this.addClass('is-open');
        $this.attr('aria-expanded', 'true');
      });

      // if dropdown group, close other panels from group
      if (panelGroup) {
        console.log('close other panels');
        const $sameGroupPanels = $('.js-dropdown-panel[aria-hidden="false"][data-dropdown-group="' + panelGroup + '"]');
        $sameGroupPanels.each(function () {
          const $this = $(this);
          if (!$this.is($panel)) {
            closeDropdown($this);
          }
        });
      }
    },
    closeDropdown = function ($panel) {

      $panel.attr('aria-hidden', 'true');
      $panel.removeClass('is-open');
      $panel.triggerHandler('dropdown/close');

      const panelID = $panel.attr('id');
      const $trigger = $('.js-dropdown-trigger[aria-controls="' + panelID + '"]');

      $trigger.each(function () {
        const $this = $(this);
        $this.removeClass('is-open');
        $this.attr('aria-expanded', 'false');
      });
    },
    toggleDropdown = function ($trigger) {

      const $panel = $('#' + $trigger.attr('aria-controls'));

      if ($panel.length) {

        if ($panel.attr('aria-hidden') === 'true') {
          openDropdown($panel);
        } else {
          closeDropdown($panel);
        }
      } else {
        console.warn('No Panel for dropDown Trigger');
      }

      // todo check for group
    },
    init = function () {

      $document.on('click', '.js-dropdown-trigger', function (e) {

        // e.preventDefault();

        const $trigger = $(this);

        toggleDropdown($trigger);

      });

      // click outside
      $document.on('click', function (e) {

        const $this = $(e.target);
        const $closestPanel = $this.closest('.js-dropdown-panel, .js-dropdown-trigger');

        // if click is not on panel or trigger
        if (!$closestPanel.length) {
          const $panels = $('.js-dropdown-panel[aria-hidden="false"]');
          // close all opened panels
          $panels.each(function () {
            closeDropdown($(this));
          });
        }

      });

      // check for deactivating dropdowns
      $window.on('orientationchange resize load', function () {

        const $panels = $('.js-dropdown-panel[data-dropdown-disable-at]');

        if ($panels.length) {
          $panels.each(function () {
            const $this = $(this);
            const disableAtMq = $this.data('dropdown-disable-at');
            const isDisabled = $this.data('dropdown-disabled');

            if (window.matchMedia(disableAtMq).matches) {
              if (!isDisabled) {
                $this.attr('aria-hidden', 'false');
                $this.data('dropdown-disabled', true);
              }
            } else {
              if (isDisabled) {
                $this.attr('aria-hidden', 'true');
                $this.data('dropdown-disabled', false);
              }
            }
          });
        }
      });

    };
  init();

})(jQuery, window, document);
