// http://www.elijahmanor.com/find-the-jquery-bug-8-suspicious-selectors/
String.prototype.escapeSelector = function () {
  return this.replace(/([!"#$%&'()*+,./:;<=>?@\[\\\]^`{|}~])/g,
    '\\$1');
};

// objectfit polyfill. replaces the image with a background image on the container object
// https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
// if ( ! Modernizr.objectfit ) {
//   $('.js-polyfill-objectfit').each(function () {
//     var $container = $(this),
//       imgUrl = $container.find('img').prop('src');    if (imgUrl) {
//       $container.css('backgroundImage', 'url(' + imgUrl + ')')
//     }
//   });
// }

// Logger for disabling console
// http://stackoverflow.com/questions/1215392/how-to-quickly-and-conveniently-disable-all-console-log-statements-in-my-code
const logger = function () {
  let oldConsoleLog = null;
  let pub = {};

  pub.enableLogger = function enableLogger () {
    if (oldConsoleLog == null) {
      return;
    }

    window['console']['log'] = oldConsoleLog;
  };

  pub.disableLogger = function disableLogger () {
    oldConsoleLog = console.log;
    window['console']['log'] = function () {};
  };

  return pub;
}();

// no-js to js class in html tag https://www.paulirish.com/2009/avoiding-the-fouc-v3/
(function (H) {
  H.className = H.className.replace(/\bno-js\b/, 'js');
})(document.documentElement);

// disable logger in live server
//logger.disableLogger();
