/*global jQuery, lottie, gsap, ScrollTrigger */
(function ($, window) {
  'use strict';

  const $document = $(document),
    $scope = $('.js-visionmission'),
    init = function () {

      // console.log('visionmission');
      // gsap.set('.js-visionmission-mission', { clipPath: 'polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%)' });

      gsap.registerPlugin(ScrollTrigger);

      gsap.set('.js-visionmission-mission', { clipPath: 'polygon(0% -1%, 20% -1%, 0% 100%, 0% 100%)' });
      gsap.to('.js-visionmission-mission', {
        scrollTrigger: {
          trigger: '.js-visionmission',
          pin: true,
          scrub: true,
          start: 'top 110px',
          end: '600px'
        },
        clipPath: 'polygon(0% -1%, 100% -1%, 80% 100%, 0% 100%)',
        duration: 1
      });

      // ScrollTrigger.refresh(true);

    };

  init();
}(jQuery, window));
