/*global jQuery, lottie, gsap, ScrollTrigger */
(function ($, window) {
  'use strict';

  const $document = $(document),
    $scope = $('.js-businessanimation'),
    init = function () {

      // console.log('businessanimation');

      const $animation = $scope.find('.js-businessanimation-animation');

      const animation = lottie.loadAnimation({
        container: $animation.get(0), // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/Animations/business-animation.json' // the path to the animation json
      });

      // gsap.registerPlugin(ScrollTrigger);

      const tl = gsap.timeline();
      // gsap.set(".js-startpagehero-intromask", {scale: 4})
      gsap.set('.js-businessanimation-push1', {opacity: 0, display: 'none'});
      gsap.set('.js-businessanimation-push2', {opacity: 0, display: 'none'});
      gsap.set('.js-businessanimation-push3', {opacity: 0, display: 'none'});


      tl.to('.js-businessanimation-push1', {opacity: 1, display: 'block', duration: 1, ease: 'none' }, 1.5);
      tl.to('.js-businessanimation-push1', {opacity: 0, display: 'none', duration: 0.5, ease: 'none' },3.5);
      tl.to('.js-businessanimation-push2', {opacity: 1, display: 'block', duration: 1, ease: 'none' }, 4);
      tl.to('.js-businessanimation-push2', {opacity: 0, display: 'none', duration: 0.5, ease: 'none' },5.5);
      tl.to('.js-businessanimation-push3', {opacity: 1, display: 'block', duration: 1, ease: 'none' },6);


      ScrollTrigger.create({
        id: 'businessanimation',
        trigger: '.js-businessanimation',
        animation: tl,
        // markers: true,
        pin: true,
        scrub: true,
        start: 'top 110px',
        end: '+=1000',
        pinSpacing: true,
        onUpdate: self => {
          animation.goToAndStop(self.progress *  (animation.totalFrames - 1), true);
        }
      });
    };

  init();
}(jQuery, window));
