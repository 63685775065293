/**
 Description: scrollcontrol Elements
 Author: Kim Christiansen

 Example:
 <div class="js-scrollcontrol" data-scrollcontrol-threshold="-120">
 <p>Content</p>
 </div>

 Added Classes: is-viewed, is-notViewed, is-inView, is-notInView

 $scope.on('scrollcontrol/isNotViewed', function(){
     console.log('scrollcontrol/isNotViewed');
   });

 $scope.on('scrollcontrol/isNotInView', function(){
     console.log('scrollcontrol/isNotInView');
   });

 $scope.on('scrollcontrol/isViewed', function(){
     console.log('scrollcontrol/isViewed');
   });

 $scope.on('scrollcontrol/isInView', function(){
     console.log('scrollcontrol/isInView');
   });

 $scope.on('scrollcontrol/onScrollUpdate', function(e, y){
     console.log('scrollcontrol/onScrollUpdate: ' + y);
   });
 **/

/*global jQuery */
(function ($, window) {
  'use strict';

  var $window,
    $document,
    timeout,
    $scope,
    $this,
    sy,
    sh,
    wy,
    wh,
    ww,
    defaultThreshold = 0,
    threshold,
    y,
    update = function () {

      wy = $window.scrollTop();
      wh = $window.height();
      ww = $window.width();

      $scope.each(function () {
        $this = $(this);

        threshold = parseInt($this.data('scrollcontrol-threshold') || defaultThreshold, 10);

        sy = Math.round($this.offset().top);
        sh = Math.round($this.height());

        y = (wy + wh - sy) / (wh + sh);
        y = Math.min(Math.max(y, 0), 1);

        if ((sy + sh) >= (wy - threshold) && sy <= ((wy + wh) + threshold)) {
          // in viewport
          $this.removeClass('is-notViewed');
          if (!$this.hasClass('is-viewed')) {
            $this.addClass('is-viewed');
            $this.triggerHandler('scrollcontrol/isViewed');
          }

          $this.removeClass('is-notInView');

          if (!$this.hasClass('is-inView')) {
            $this.addClass('is-inView');
            $this.triggerHandler('scrollcontrol/isInView');
          }
        } else {
          // not visible
          if (!$this.hasClass('is-viewed')) {

            if (!$this.hasClass('is-notViewed')) {
              $this.addClass('is-notViewed');
              $this.triggerHandler('scrollcontrol/isNotViewed');
            }
          }

          $this.removeClass('is-inView');

          if (!$this.hasClass('is-notInView')) {
            $this.addClass('is-notInView');
            $this.triggerHandler('scrollcontrol/isNotInView');
          }

        }


        // check if object is above top of viewport
        if (sy <= wy) {
          // $this.removeClass('')

          $this.removeClass('is-notAboveTop');

          if (!$this.hasClass('is-aboveTop')) {
            // console.log('is-aboveTop');
            $this.addClass('is-aboveTop');
            $this.triggerHandler('scrollcontrol/isAboveTop');
          }

        } else {

          $this.removeClass('is-aboveTop');

          if (!$this.hasClass('is-notAboveTop')) {
            // console.log('is-notAboveTop');
            $this.addClass('is-notAboveTop');
            $this.triggerHandler('scrollcontrol/isNotAboveTop');
          }
        }


        // check if object is above bottom on viewport
        if ((sy + sh) < (wy + wh)) {
          $this.removeClass('is-notAboveBottom');

          if (!$this.hasClass('is-aboveBottom')) {
            // console.log('is-aboveBottom');
            $this.addClass('is-aboveBottom');
            $this.triggerHandler('scrollcontrol/isAboveBottom');
          }
        } else {

          $this.removeClass('is-aboveBottom');

          if (!$this.hasClass('is-notAboveBottom')) {
            // console.log('is-notAboveBottom');
            $this.addClass('is-notAboveBottom');
            $this.triggerHandler('scrollcontrol/isNotAboveBottom');
          }
        }


        if (y !== $this.data('scrollcontrol-y')) {

          $this.triggerHandler('scrollcontrol/onScrollUpdate', y);

          $this.data('scrollcontrol-y', y);

        }

      });

    },
    init = function () {
      $window = $(window);
      $document = $(document);

      $document.ready(function () {
        $scope = $('.js-scrollcontrol');

        if ($scope.length) {
          // $scope.data('scrollcontrol-y',-1);
          $window.on('orientationchange resize scroll load', function () {

            if (timeout) {
              cancelAnimationFrame(timeout);
            }

            timeout = requestAnimationFrame(update);

          });
        }
      });

    };

  init();
}(jQuery, window));
