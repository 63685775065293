/*global jQuery */
(function ($, window, document, undefined) {
  'use strict';
  var $document = $(document),

    toggleAccordion = function ($accordion, $accordionItem) {

      const isMultiexpand = !!$accordion.data('accordion-multiexpand');

      if ($accordionItem.hasClass('is-open')) {
        closePanel($accordionItem);

      } else {
        openPanel($accordionItem);
      }

      if (!isMultiexpand) {
        $accordion.find('.js-accordion-item.is-open').each(function () {
          const $this = $(this);

          if (!$accordionItem.is($this)) {
            closePanel($this);
          }
        });
      }
    },
    openPanel = function ($accordionItem) {
      var $accordionLink = $accordionItem.find('.js-accordion-trigger');
      var $accordionPanel = $accordionItem.find('.js-accordion-panel');

      $accordionPanel.attr('aria-hidden', 'false');
      $accordionPanel.slideDown(200);

      $accordionItem.addClass('is-open');
      $accordionLink.attr('aria-expanded', 'true');

      $document.triggerHandler('global/reflow');
    },
    closePanel = function ($accordionItem) {

      var $accordionLink = $accordionItem.find('.js-accordion-trigger');
      var $accordionPanel = $accordionItem.find('.js-accordion-panel');

      $accordionPanel.attr('aria-hidden', 'true');
      $accordionPanel.slideUp(200);

      $accordionItem.removeClass('is-open');
      $accordionLink.attr('aria-expanded', 'false');

      $document.triggerHandler('global/reflow');
    },
    init = function () {

      $document.on('click', '.js-accordion-trigger', function (e) {

        e.preventDefault();

        const $this = $(this);
        const $accordion = $this.closest('.js-accordion');
        const $accordionItem = $this.parent('.js-accordion-item');

        toggleAccordion($accordion, $accordionItem);

      });

      // initial open Accordion from anchor link
      if (window.location.hash) {
        let hash = window.location.hash;

        // replace !$&= in hash for clean selector and solve Error: Syntax error, unrecognized expression
        hash = hash.replace(/([!"!$&='()*+,./:;<=>?@\[\\\]^`{|}~])/g, '-');

        if ($(hash).length) {
          if ($(hash).hasClass('js-accordion-panel')) {

            const $accordionPanel = $(hash);
            var $accordionItem = $accordionPanel.parent('.js-accordion-item');

            openPanel($accordionItem);
          }
        }
      }

    };
  init();

})(jQuery, window, document);
