/*global jQuery */
(function ($, window, document) {
  'use strict';
  var $document = $(document),
    $scope = $('.js-challenges'),
    init = function () {

      $scope.each(function(){
        const $this = $(this);
        const $pictogram = $this.find('.js-challenges-pictogram');
        $this.on('scrollcontrol/onScrollUpdate', function(e, y){

          const scale = Math.sin(y * Math.PI);
          $pictogram.css('transform', 'scale(' + scale + ')');
        });
      });

    };
  init();

})(jQuery, window, document);
