/**
 Description: Checking if user has scrolled down or up again
 Author: Kim Christiansen

 $document.on('scrollcheck/isScroll', function(){
   console.log('scrollcheck/isScroll');
 });

 */

/*global jQuery */
(function ($, window, document, undefined) {
  'use strict';
  var scrollTop,
    offsetTriggerMobile = 110,
    offsetTriggerDesktop = 110, //ServiceNavigation posY - ServiceNavigation sticky posY
    offsetTriggerY,
    $window,
    $document,
    $docElement,
    isScrolled,
    isScrollingUp,
    lastScrollTop,

    update = function () {
      scrollTop = Math.round($window.scrollTop());

      const windowWidth = $window.width();
      offsetTriggerY = offsetTriggerMobile;

      if (windowWidth >= 768) {
        offsetTriggerY = offsetTriggerDesktop;
      }

      if (scrollTop > offsetTriggerY) {
        if (!isScrolled) {
          isScrolled = true;

          $docElement.removeClass('is-noScroll');
          $docElement.addClass('is-scroll');
          $document.triggerHandler('scrollcheck/isScroll');
        }
      } else {

        if (isScrolled) {
          isScrolled = false;

          $docElement.addClass('is-noScroll');
          $docElement.removeClass('is-scroll');
          $document.triggerHandler('scrollcheck/isNoScroll');
        }
      }

      if (scrollTop > lastScrollTop || scrollTop === 0) {
        if (isScrollingUp) {
          isScrollingUp = false;

          $docElement.removeClass('is-scrollTop');
          $document.triggerHandler('scrollcheck/isScrollDown');
        }
      } else {
        if (!isScrollingUp) {
          isScrollingUp = true;

          $docElement.addClass('is-scrollTop');
          $document.triggerHandler('scrollcheck/isScrollTop');
        }
      }

      lastScrollTop = scrollTop;
    },

    init = function () {
      $window = $(window);
      $document = $(document);
      $docElement = $(document.documentElement);

      isScrolled = false;
      isScrollingUp = false;
      lastScrollTop = Math.round($window.scrollTop());

      $window.on('orientationchange resize scroll load', function () {

        update();
      });

      $docElement.addClass('is-noScroll');
      update();
    };

  init();

})(jQuery, window, document);
